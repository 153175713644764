import { Fragment } from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';

import { Card, CardContent, CardHeader, Content, Image } from './ProjectCheckoutSummary.mobile.sticky.styles';
import { ProjectCheckoutSummaryProps } from './ProjectCheckoutSummary.types';
import { getProjectCheckoutSummaryImage } from './ProjectCheckoutSummary.utils';

export function ProjectCheckoutSummaryMobileSticky({ type, title, products, includes }: ProjectCheckoutSummaryProps) {
  const image = getProjectCheckoutSummaryImage(type, products);

  return (
    <Card>
      <CardHeader>
        <RMText type="sans" size="xs" bold color="on-surface-primary">
          {title}
        </RMText>
        <RMText type="sans" size="xs" bold color="on-surface-secondary">
          ${products.price}
        </RMText>
      </CardHeader>
      <CardContent>
        <Image src={image.src} alt="book" data-portrait={image.portrait} />
        <Content>
          {includes.map((productInclude) => (
            <Fragment key={productInclude}>
              <RMSpacer spacing="xs" direction="column" />
              <RMStack spacing="xs" direction="row" center>
                <FontAwesomeIcon size="xs" icon={faCheck} color="var(--on-surface-primary)" />
                <RMText type="sans" size="xxs" color="on-surface-primary">
                  {productInclude}
                </RMText>
              </RMStack>
            </Fragment>
          ))}
        </Content>
      </CardContent>
    </Card>
  );
}
