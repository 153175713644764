import { CheckoutRequest } from '@/services/api/checkout';
import { getMarketingCookies } from '@/utils/marketing-cookies';

import { getInputValue } from '../form/input';
import { RoutePath } from '../routing/types/routing.types';

import { getCheckoutData, ProjectCheckoutManager, ProjectCheckoutStep, ProjectType } from './project-checkout.manager';

async function identifyCheckoutOwner(manager: ProjectCheckoutManager): Promise<void> {
  const projectType = getInputValue(manager.form, 'type');
  const ownerEmail = getInputValue(manager.form, 'owner.email');
  const ownerFirstName = getInputValue(manager.form, 'owner.person.firstName');
  const ownerLastName = getInputValue(manager.form, 'owner.person.lastName');
  if (!projectType || !ownerEmail || !ownerFirstName || !ownerLastName) {
    throw new Error('Missing checkout contact form value');
  }
  await manager.services.analyticsService.identifyEmail(ownerEmail, {
    name: `${ownerFirstName} ${ownerLastName}`,
    email: ownerEmail,
    currentCheckoutUseCase:
      projectType === 'AUTOBIOGRAPHY' ? 'autobiography' : projectType === 'BIOGRAPHY' ? 'biography' : 'babybook',
  });
  manager.services.checkoutAnalyticsService.onCheckoutContactFormSubmitted();
}

async function handleCheckout(manager: ProjectCheckoutManager): Promise<void> {
  const checkoutData = getCheckoutData(manager);
  const marketingCookies = getMarketingCookies();
  manager.services.checkoutAnalyticsService.onCheckoutStarted(marketingCookies);
  const checkout = await manager.services.checkoutService.checkout({
    ...checkoutData,
    ...marketingCookies,
    credits: checkoutData.products,
  } as CheckoutRequest);
  window.open(checkout.paymentUrl, '_self');
}

export const BIOGRAPHY_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'audience',
    label: 'Storyteller',
    route: RoutePath.CheckoutAudience,
    inputs: [],
  },
  {
    name: 'delivery',
    label: 'Delivery',
    route: RoutePath.CheckoutBiographyDelivery,
    inputs: [
      'recipient.email',
      'recipient.phone',
      'recipient.person.firstName',
      'recipient.person.lastName',
      'gift.from',
      'gift.message',
      'gift.sendOn',
    ],
    postStepCallback: async (manager) => {
      const recipientFirstName = getInputValue(manager.form, 'recipient.person.firstName');
      const recipientLastName = getInputValue(manager.form, 'recipient.person.lastName');
      if (!recipientFirstName || !recipientLastName) {
        throw new Error('Missing checkout storyteller form value');
      }
      manager.services.checkoutAnalyticsService.onCheckoutStorytellerFormSubmitted(
        recipientFirstName,
        recipientLastName,
      );
    },
  },
  {
    name: 'contact',
    label: 'Details',
    route: RoutePath.CheckoutBiographyContact,
    inputs: [
      'owner.email',
      'owner.person.firstName',
      'owner.person.lastName',
      'owner.phone',
      'products.books',
      'products.ebook',
    ],
    postStepCallback: identifyCheckoutOwner,
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutBiographyReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const AUTOBIOGRAPHY_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'audience',
    label: 'Storyteller',
    route: RoutePath.CheckoutAudience,
    inputs: [],
  },
  {
    name: 'contact',
    label: 'Details',
    route: RoutePath.CheckoutAutobiographyContact,
    inputs: [
      'owner.email',
      'owner.person.firstName',
      'owner.person.lastName',
      'owner.phone',
      'products.books',
      'products.ebook',
    ],
    postStepCallback: identifyCheckoutOwner,
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutAutobiographyReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const BABYBOOK_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'audience',
    label: 'Project type',
    route: RoutePath.CheckoutBabyAudience,
    inputs: [],
  },
  {
    name: 'contact',
    label: 'Your profile',
    route: RoutePath.CheckoutBabyHost,
    inputs: ['owner.email', 'owner.person.firstName', 'owner.person.lastName', 'owner.phone', 'subject.firstName'],
    postStepCallback: identifyCheckoutOwner,
  },
  {
    name: 'details',
    label: 'Book details',
    route: RoutePath.CheckoutBabyProject,
    inputs: ['name', 'products.books', 'products.ebook'],
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutBabyReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const FREE_PROJECT_CHECKOUT_STEPS: ProjectCheckoutStep[] = [
  {
    name: 'details',
    label: 'Details',
    route: RoutePath.CheckoutFreeDetails,
    inputs: ['recipientPersonId', 'subscriptionOwnerPersonId', 'name', 'products.books', 'products.ebook'],
  },
  {
    name: 'review',
    label: 'Review',
    route: RoutePath.CheckoutFreeReview,
    inputs: [],
    postStepCallback: handleCheckout,
  },
];

export const PROJECT_CHECKOUT_STEPS: Record<ProjectType, ProjectCheckoutStep[]> = {
  BIOGRAPHY: BIOGRAPHY_PROJECT_CHECKOUT_STEPS,
  AUTOBIOGRAPHY: AUTOBIOGRAPHY_PROJECT_CHECKOUT_STEPS,
  BABYBOOK: BABYBOOK_PROJECT_CHECKOUT_STEPS,
  FREE: FREE_PROJECT_CHECKOUT_STEPS,
};
