import { Fragment } from 'react';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMStack } from '@/components/RMStack/RMStack';
import { RMText } from '@/components/RMText/RMText';

import { Container, Image } from './ProjectCheckoutSummary.desktop.styles';
import { ProjectCheckoutSummaryProps } from './ProjectCheckoutSummary.types';
import { getProjectCheckoutSummaryImage, getProjectCheckoutSummaryPreloadImages } from './ProjectCheckoutSummary.utils';

export function ProjectCheckoutSummaryDesktop({ type, title, products, includes }: ProjectCheckoutSummaryProps) {
  const image = getProjectCheckoutSummaryImage(type, products);
  const preloadImages = getProjectCheckoutSummaryPreloadImages(type);

  return (
    <Container>
      {/* Preload the image to prevent animation issues */}
      {preloadImages.map((src) => (
        <img key={src} src={src} style={{ display: 'none' }} />
      ))}

      <Image src={image.src} data-portrait={image.portrait} />
      <RMText type="serif" size="xl" color="on-surface-primary">
        {title}
      </RMText>
      <RMSpacer spacing="sm" direction="column" />
      <RMStack spacing="sm" direction="row" center>
        <RMText type="serif" size="l" color="on-surface-primary">
          Total: <b>${products.price.toFixed(2).replace(/[.,]00$/, '')}</b>
        </RMText>

        {products.price != products.officialPrice && (
          <RMText
            type="serif"
            size="l"
            color="on-surface-tertiary"
            style={{ textDecoration: 'line-through', alignSelf: 'self-end' }}
          >
            ${products.officialPrice.toFixed(2).replace(/[.,]00$/, '')}
          </RMText>
        )}
      </RMStack>
      <RMSpacer spacing="2xl" direction="column" />
      <RMText type="sans" size="xxs" bold color="on-surface-secondary" trackedOutCaps>
        INCLUDES
      </RMText>
      <RMSpacer spacing="xl" direction="column" />
      {includes.map((productInclude) => (
        <Fragment key={productInclude}>
          <RMStack spacing="xs" direction="row" center>
            <FontAwesomeIcon icon={faCheck} color="var(--on-surface-primary)" />
            <RMText type="sans" size="s" color="on-surface-primary">
              {productInclude}
            </RMText>
          </RMStack>
          <RMSpacer spacing="xs" direction="column" />
        </Fragment>
      ))}
    </Container>
  );
}
