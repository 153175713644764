import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputMasked } from '@/components/RMInputMasked/RMInputMasked';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';

import { ProjectCheckoutForm } from '../../project-checkout.form';

import { Container, Field, FormGrid } from './BabybookProjectCheckoutContactForm.styles';

export interface BabybookProjectCheckoutContactFormProps {
  form: ProjectCheckoutForm;
  firstNameDisabled: boolean;
  lastNameDisabled: boolean;
  emailDisabled: boolean;
  phoneDisabled: boolean;
}

export function BabybookProjectCheckoutContactForm({
  form,
  firstNameDisabled,
  lastNameDisabled,
  emailDisabled,
  phoneDisabled,
}: BabybookProjectCheckoutContactFormProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Your profile
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputContainer form={form} path="owner.person.firstName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="host-first-name"
                label={isMobile ? 'Your first name' : 'Your name'}
                placeholder="First name"
                disabled={firstNameDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="owner.person.lastName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="host-last-name"
                // \u00A0 === &nbsp;
                // We need this to keep both inputs at the same position.
                label={isMobile ? 'Your last name' : '\u00A0'}
                placeholder="Last name"
                disabled={lastNameDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="owner.email">
          {(props) => (
            <Field size="large">
              <RMInput
                id="host-email"
                label="Your email"
                placeholder="Your email"
                autoCapitalize="none"
                type="email"
                disabled={emailDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputController form={form} path="owner.phone">
          {(props) => (
            <Field size="large">
              <RMInputMasked
                id="host-phone"
                label="Your phone number (optional)"
                placeholder="(XXX) XXX-XXXX"
                mask="(000) 000-0000"
                autoCapitalize="none"
                inputMode="tel"
                value={props.value ?? ''}
                error={props.error}
                disabled={phoneDisabled}
                unmask={true}
                onBlur={props.onBlur}
                onAccept={(unmaskedValue: string) => {
                  if (unmaskedValue !== props.value) {
                    props.onChange(unmaskedValue);
                  }
                }}
              />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Remento sends helpful reminders via text. Standard text message rates may apply. Unsubscribe at any time
                by messaging STOP or disabling in your account settings.
              </RMText>
            </Field>
          )}
        </InputController>
        <InputContainer form={form} path="subject.firstName">
          {(props) => (
            <Field size="large">
              <RMInput id="baby-first-name" label="Your baby's name" placeholder="First name" {...props} />
            </Field>
          )}
        </InputContainer>
      </FormGrid>
    </Container>
  );
}
