import { RMInput } from '@/components/RMInput/RMInput';
import { RMInputMasked } from '@/components/RMInputMasked/RMInputMasked';
import { RMSelect } from '@/components/RMSelect';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { InputContainer, InputController } from '@/modules/form/container';

import { ProjectCheckoutForm } from '../../project-checkout.form';
import { ProjectCheckoutEbookCheckbox } from '../ProjectCheckoutEbookCheckbox/ProjectCheckoutEbookCheckbox';

import { Container, Field, FormGrid } from './BiographyProjectCheckoutContactForm.styles';

const QUANTITY_OPTIONS = Array.from({ length: 100 }).map((_, i) => ({
  label: `${i + 1} ${i === 0 ? '(included in subscription)' : `(${i} extra book) - $${59 * i} (save $${10 * i})`}`,
  value: `${i + 1}`,
}));

export interface BiographyProjectCheckoutContactFormProps {
  form: ProjectCheckoutForm;
  firstNameDisabled: boolean;
  lastNameDisabled: boolean;
  emailDisabled: boolean;
  phoneDisabled: boolean;
}

export function BiographyProjectCheckoutContactForm({
  form,
  firstNameDisabled,
  lastNameDisabled,
  emailDisabled,
  phoneDisabled,
}: BiographyProjectCheckoutContactFormProps) {
  const isMobile = useIsMobileViewport();

  return (
    <Container>
      <RMText type="serif" size={isMobile ? 'l' : 'xl'} color="on-surface-primary">
        Purchase details
      </RMText>
      <RMSpacer spacing="2xl" direction="column" />
      <FormGrid>
        <InputContainer form={form} path="owner.person.firstName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="host-first-name"
                label={isMobile ? 'Your first name' : 'Your name'}
                placeholder="Your first name"
                disabled={firstNameDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="owner.person.lastName">
          {(props) => (
            <Field size="small">
              <RMInput
                id="host-last-name"
                // \u00A0 === &nbsp;
                // We need this to keep both inputs at the same position.
                label={isMobile ? 'Your last name' : '\u00A0'}
                placeholder="Your last name"
                disabled={lastNameDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputContainer form={form} path="owner.email">
          {(props) => (
            <Field size="large">
              <RMInput
                id="host-email"
                label="Your email"
                placeholder="Your email"
                autoCapitalize="none"
                type="email"
                disabled={emailDisabled}
                {...props}
              />
            </Field>
          )}
        </InputContainer>
        <InputController form={form} path="owner.phone">
          {(props) => (
            <Field size="large">
              <RMInputMasked
                id="host-phone"
                label="Your phone number (optional)"
                placeholder="(XXX) XXX-XXXX"
                mask="(000) 000-0000"
                autoCapitalize="none"
                inputMode="tel"
                value={props.value ?? ''}
                error={props.error}
                disabled={phoneDisabled}
                unmask={true}
                onBlur={props.onBlur}
                onAccept={(unmaskedValue: string) => {
                  if (unmaskedValue !== props.value) {
                    props.onChange(unmaskedValue);
                  }
                }}
              />
              <RMSpacer spacing="xs" direction="column" />
              <RMText size="xs" type="sans" color="on-surface-tertiary">
                Remento sends helpful reminders via text. Standard text message rates may apply. Unsubscribe at any time
                by messaging STOP or disabling in your account settings.
              </RMText>
            </Field>
          )}
        </InputController>

        <InputController form={form} path="products.books">
          {({ value, onChange, onBlur }) => (
            <Field size="large">
              <RMText type="sans" size="xs" color="on-surface-secondary" bold>
                Number of books
              </RMText>
              <RMSpacer direction="column" spacing="xs" />

              <RMSelect
                id="quantity-select"
                options={QUANTITY_OPTIONS}
                value={value != null ? String(value) : '1'}
                onChange={(newValue) => {
                  onChange(parseInt(newValue, 10));
                  onBlur();
                }}
              />
            </Field>
          )}
        </InputController>

        <InputController form={form} path="products.ebook">
          {({ value, onChange }) => (
            <Field size="large">
              <ProjectCheckoutEbookCheckbox value={!!value} onChange={onChange} />
            </Field>
          )}
        </InputController>
      </FormGrid>
    </Container>
  );
}
