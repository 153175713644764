import { useCallback, useEffect, useMemo, useRef } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { createStore, StoreApi, useStore } from 'zustand';

import { Services, useServices } from '@/Services';

import { Paths } from '../form/@types-utils';
import { watchFormInputsValue } from '../form/form';
import { getInputValue, getInputValueOrThrow, setInputValues, useInputValue } from '../form/input';
import { everyInputValid, watchEveryInputValid } from '../form/input.utils';
import { RoutePath } from '../routing/types/routing.types';

import { ProjectCheckoutForm, ProjectCheckoutFormInput } from './project-checkout.form';
import { ProjectCheckoutServices, useProjectCheckoutServices } from './project-checkout-services.context.';

/**** MANAGER TYPES ****/

export interface ProjectCheckoutPerson {
  firstName: string;
  lastName: string;
}

export interface ProjectCheckoutUser {
  person: ProjectCheckoutPerson;
  email: string;
  phone?: string | null | undefined;
}

export interface ProjectCheckoutProducts {
  ebook: boolean;
  books: number;
}

export interface ProjectCheckoutGift {
  from: string;
  message: string;
  sendOn: number;
}

export type ProjectType = 'BIOGRAPHY' | 'AUTOBIOGRAPHY' | 'BABYBOOK' | 'FREE';

export interface ProjectCheckoutFormData {
  /**
   * The type of the project.
   */
  type: ProjectType;
  /**
   * A stripe coupon to be applied to the order.
   */
  coupon?: string | null;
  /**
   * The owner of the project.
   */
  owner: ProjectCheckoutUser;
  /**
   * The additional products to be purchased with the project.
   */
  products: ProjectCheckoutProducts;
  /**
   * The gift information.
   * This is only used when the project is a `BIOGRAPHY`.
   */
  gift?: ProjectCheckoutGift;
  /**
   * The subject of the project. (idk why this is called recipient).
   * This is only used when the project is a `BIOGRAPHY`.
   */
  recipient?: ProjectCheckoutUser;
  /**
   * The name of the project.
   * This is only used when the project is a `BABYBOOK` or `FREE`.
   */
  name?: string;
  /**
   * The subject of the project.
   * This is only used when the project is a `BABYBOOK`.
   */
  subject?: ProjectCheckoutPerson;
  /**
   * The person ID of the recipient of the project.
   * This is only used when the project is a `FREE`.
   */
  recipientPersonId?: string;
  /**
   * The person ID of the owner of the subscription.
   * This is only used when the project is a `FREE`.
   */
  subscriptionOwnerPersonId?: string;
}

export interface ProjectCheckoutStep {
  name: string;
  label: string;
  route: RoutePath;
  inputs: Array<Paths<ProjectCheckoutFormInput>>;
  preStepCallback?: (manager: ProjectCheckoutManager) => Promise<void>;
  postStepCallback?: (manager: ProjectCheckoutManager) => Promise<void>;
}

/**** MANAGER STORE ****/

export interface ProjectCheckoutCurrentStep {
  name: string;
  index: number;
}

export interface ProjectCheckoutManagerState {
  currentStep: ProjectCheckoutCurrentStep;
  validSteps: Partial<Record<string, boolean>>;
}

export type ProjectCheckoutManagerStore = StoreApi<ProjectCheckoutManagerState>;

/**** MANAGER LOGIC ****/

export interface ProjectCheckoutManager {
  services: Services & ProjectCheckoutServices;
  navigate: NavigateFunction;
  form: ProjectCheckoutForm;
  steps: Array<ProjectCheckoutStep>;
  store: ProjectCheckoutManagerStore;
  destroy: () => void;
}

export function createProjectCheckoutManager(
  services: Services & ProjectCheckoutServices,
  navigate: NavigateFunction,
  form: ProjectCheckoutForm,
  steps: Array<ProjectCheckoutStep>,
): ProjectCheckoutManager {
  const store = createStore<ProjectCheckoutManagerState>(() => ({
    validSteps: {},
    currentStep: {
      index: 0,
      name: steps[0].name,
    },
  }));

  const cleanups: (() => void)[] = [];

  // Restore the form from the local repository.
  // We should not do this for `FREE` projects.
  if (getInputValue(form, 'type') !== 'FREE') {
    const formValue = services.projectCheckoutFormRepository.get();
    if (formValue != null) {
      setInputValues(form, {
        ...formValue,
        // Never restore the type property. It will always be defined.
        type: getInputValue(form, 'type') ?? undefined,
        // And we should be careful to not override these properties if they
        // are already set to something else
        coupon: getInputValue(form, 'coupon') ?? formValue.coupon,
        owner: {
          person: {
            firstName: getInputValue(form, 'owner.person.firstName') ?? formValue.owner?.person?.firstName,
            lastName: getInputValue(form, 'owner.person.lastName') ?? formValue.owner?.person?.lastName,
          },
          email: getInputValue(form, 'owner.email') ?? formValue.owner?.email,
          phone: getInputValue(form, 'owner.phone') ?? formValue.owner?.phone,
        },
        recipientPersonId: getInputValue(form, 'recipientPersonId') ?? formValue.recipientPersonId,
        subscriptionOwnerPersonId:
          getInputValue(form, 'subscriptionOwnerPersonId') ?? formValue.subscriptionOwnerPersonId,
      });
    }

    cleanups.push(
      watchFormInputsValue(form, (value) => {
        if (value != null) {
          services.projectCheckoutFormRepository.set(value);
        }
      }),
    );
  }

  // Watch the validation of every form.

  for (const step of steps) {
    if (everyInputValid(form, step.inputs)) {
      store.setState(
        (state) => ({
          ...state,
          validSteps: {
            ...state.validSteps,
            [step.name]: true,
          },
        }),
        true,
      );
    }

    const cleanup = watchEveryInputValid(form, step.inputs, (isValid) => {
      store.setState(
        (state) => ({
          ...state,
          validSteps: {
            ...state.validSteps,
            [step.name]: isValid,
          },
        }),
        true,
      );
    });
    cleanups.push(cleanup);
  }

  const destroy = () => {
    for (const cleanup of cleanups) {
      cleanup();
    }
  };

  return { services, navigate, form, steps, store, destroy };
}

export function useCreateProjectCheckoutManager(
  form: ProjectCheckoutForm,
  steps: Array<ProjectCheckoutStep>,
): ProjectCheckoutManager {
  const services = useServices();
  const projectCheckoutServices = useProjectCheckoutServices();

  const navigate = useNavigate();

  const manager = useMemo(
    () => createProjectCheckoutManager({ ...services, ...projectCheckoutServices }, navigate, form, steps),
    [form, navigate, projectCheckoutServices, services, steps],
  );

  // Destroy the manager on unmount.
  // If we are running using hot reload, we should skip the first
  // cleanup because of the strict mode.
  const skipCleanup = useRef(import.meta.hot != null);
  useEffect(() => {
    return () => {
      if (skipCleanup.current) {
        skipCleanup.current = false;
        return;
      }

      manager.destroy();
    };
  }, [manager]);

  return manager;
}

export function isStepAvailable(manager: ProjectCheckoutManager, name: string): boolean {
  const { validSteps } = manager.store.getState();

  const stepIndex = manager.steps.findIndex((s) => s.name == name);
  if (stepIndex === -1) {
    return false;
  }
  const previousSteps = manager.steps.slice(0, stepIndex);
  return previousSteps.every((step) => validSteps[step.name] === true);
}

export function useIsStepAvailable(manager: ProjectCheckoutManager, name: string): boolean {
  const validSteps = useStore(
    manager.store,
    useCallback((state) => state.validSteps, []),
  );

  const stepIndex = manager.steps.findIndex((s) => s.name == name);
  if (stepIndex === -1) {
    return false;
  }
  const previousSteps = manager.steps.slice(0, stepIndex);
  return previousSteps.every((step) => validSteps[step.name] === true);
}

export function useIsNextStepAvailable(manager: ProjectCheckoutManager): boolean {
  const currentStep = useStore(
    manager.store,
    useCallback((state) => state.currentStep, []),
  );
  const validSteps = useStore(
    manager.store,
    useCallback((state) => state.validSteps, []),
  );
  return validSteps[currentStep.name] ?? false;
}

export function useIsStepValid(manager: ProjectCheckoutManager, name: string): boolean {
  const validSteps = useStore(
    manager.store,
    useCallback((state) => state.validSteps, []),
  );
  return validSteps[name] ?? false;
}

export function getCurrentStep(manager: ProjectCheckoutManager): ProjectCheckoutCurrentStep {
  return manager.store.getState().currentStep;
}

export function useCurrentStep(manager: ProjectCheckoutManager): ProjectCheckoutCurrentStep {
  return useStore(
    manager.store,
    useCallback((state) => state.currentStep, []),
  );
}

export function useCheckoutProducts(manager: ProjectCheckoutManager): {
  price: number;
  officialPrice: number;
  ebook: boolean;
  books: number;
} {
  const projectType = useInputValue(manager.form, 'type');
  const coupon = useInputValue(manager.form, 'coupon');
  const books = useInputValue(manager.form, 'products.books') ?? 0;
  const ebook = useInputValue(manager.form, 'products.ebook') ?? false;

  if (projectType == null) {
    // This will never happen.
    throw new Error('Project type cannot be null');
  }

  const priceQuery = useQuery({
    queryKey: ['checkout-price', { projectType, coupon, books, ebook }],
    queryFn: () =>
      manager.services.checkoutService.getCheckoutTotal({
        projectType,
        coupon,
        books,
        ebook,
      }),
  });

  const officialSubscriptionPrice = projectType === 'FREE' ? 0 : 168;
  const subscriptionPrice = projectType === 'FREE' ? 0 : 99;
  const includedBooks = projectType === 'FREE' ? 0 : 1;

  const officialPrice = officialSubscriptionPrice + 69 * (books - includedBooks) + (ebook ? 49.99 : 0);
  // This will be used as a backup while the priceQuery is loading.
  const price = subscriptionPrice + 59 * (books - includedBooks) + (ebook ? 24.99 : 0);

  return { price: priceQuery.data?.total ?? price, officialPrice, ebook, books };
}

export function getCheckoutData(manager: ProjectCheckoutManager): ProjectCheckoutFormData {
  // This method can will only be called at the end, so we are sure that the data is present and valid.
  const type = getInputValueOrThrow(manager.form, 'type');
  const owner: ProjectCheckoutUser = {
    email: getInputValueOrThrow(manager.form, 'owner.email'),
    phone: getInputValue(manager.form, 'owner.phone'),
    person: {
      firstName: getInputValueOrThrow(manager.form, 'owner.person.firstName'),
      lastName: getInputValueOrThrow(manager.form, 'owner.person.lastName'),
    },
  };
  const products: ProjectCheckoutProducts = {
    books: getInputValueOrThrow(manager.form, 'products.books'),
    ebook: getInputValueOrThrow(manager.form, 'products.ebook'),
  };

  switch (type) {
    case 'AUTOBIOGRAPHY': {
      return {
        type,
        coupon: getInputValue(manager.form, 'coupon'),
        owner,
        products,
      };
    }
    case 'BIOGRAPHY': {
      const recipient: ProjectCheckoutUser = {
        email: getInputValueOrThrow(manager.form, 'recipient.email'),
        phone: getInputValue(manager.form, 'recipient.phone'),
        person: {
          firstName: getInputValueOrThrow(manager.form, 'recipient.person.firstName'),
          lastName: getInputValueOrThrow(manager.form, 'recipient.person.lastName'),
        },
      };

      const sendOnDate = new Date(getInputValueOrThrow(manager.form, 'gift.sendOn'));
      sendOnDate.setMinutes(sendOnDate.getMinutes() + sendOnDate.getTimezoneOffset());

      const gift: ProjectCheckoutGift = {
        from: getInputValueOrThrow(manager.form, 'gift.from'),
        message: getInputValueOrThrow(manager.form, 'gift.message'),
        sendOn: sendOnDate.getTime(),
      };

      return {
        type,
        coupon: getInputValue(manager.form, 'coupon'),
        owner,
        products,
        gift,
        recipient,
      };
    }
    case 'BABYBOOK': {
      const subjectFirstName = getInputValue(manager.form, 'subject.firstName');
      const subject: ProjectCheckoutPerson = {
        firstName: subjectFirstName == null || subjectFirstName.length == 0 ? 'Baby' : subjectFirstName,
        lastName: owner.person.lastName,
      };

      return {
        type,
        coupon: getInputValue(manager.form, 'coupon'),
        owner,
        products,
        name: getInputValueOrThrow(manager.form, 'name'),
        subject,
      };
    }
    case 'FREE': {
      return {
        type,
        coupon: getInputValue(manager.form, 'coupon'),
        owner,
        products,
        name: getInputValueOrThrow(manager.form, 'name'),
        recipientPersonId: getInputValueOrThrow(manager.form, 'recipientPersonId'),
        subscriptionOwnerPersonId: getInputValueOrThrow(manager.form, 'subscriptionOwnerPersonId'),
      };
    }
  }
}

export async function goToStep(manager: ProjectCheckoutManager, name: string, replace = false): Promise<boolean> {
  const stepIndex = manager.steps.findIndex((s) => s.name == name);
  if (isStepAvailable(manager, name) === false) {
    if (stepIndex > 0) {
      await goToStep(manager, manager.steps[stepIndex - 1].name, true);
      return false;
    }
    return false;
  }
  const { currentStep } = manager.store.getState();
  const step = manager.steps[stepIndex];

  // We should only run the `postStepCallback` function if we are
  // going to the next step. If the user is going back, it should not be triggered.
  if (stepIndex > currentStep.index) {
    await manager.steps[currentStep.index].postStepCallback?.(manager);
  }

  await step.preStepCallback?.(manager);

  if (currentStep.name !== name) {
    manager.store.setState({
      currentStep: {
        name: step.name,
        index: stepIndex,
      },
    });
  }

  // Make sure that we are at the right page, even if the state is the same
  if (window.location.pathname !== step.route) {
    manager.navigate(step.route, { replace });
  }

  return true;
}

export async function goToNextStep(manager: ProjectCheckoutManager): Promise<boolean> {
  const { currentStep, validSteps } = manager.store.getState();
  if (validSteps[currentStep.name] !== true) {
    return false;
  }

  if (currentStep.index + 1 >= manager.steps.length) {
    // There's no next step to go
    // We should run the postStepCallback of the last step.
    await manager.steps[currentStep.index].postStepCallback?.(manager);
    return false;
  }

  return goToStep(manager, manager.steps[currentStep.index + 1].name);
}
