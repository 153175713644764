import { useMemo } from 'react';

import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import { useInputValue } from '@/modules/form/input';
import { usePersonQuery } from '@/services/api/person';
import { genitiveCase } from '@/utils/genitiveCase';

import { ProjectCheckoutSummaryDesktop } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.desktop';
import { ProjectCheckoutSummaryMobile } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.mobile';
import { ProjectCheckoutSummaryMobileSticky } from '../components/ProjectCheckoutSummary/ProjectCheckoutSummary.mobile.sticky';
import { ProjectCheckoutManager, useCheckoutProducts } from '../project-checkout.manager';

export interface ProjectCheckoutSummaryContainerProps {
  manager: ProjectCheckoutManager;
  sticky?: boolean;
}

export function ProjectCheckoutSummaryContainer({ manager, sticky = false }: ProjectCheckoutSummaryContainerProps) {
  const isMobile = useIsMobileViewport();

  const projectType = useInputValue(manager.form, 'type');
  const products = useCheckoutProducts(manager);

  const storytellerPersonId = useInputValue(manager.form, 'recipientPersonId');
  const storytellerPersonQuery = usePersonQuery(storytellerPersonId);

  const title = useMemo(() => {
    switch (projectType) {
      case 'BIOGRAPHY':
      case 'AUTOBIOGRAPHY': {
        return 'The Remento Book';
      }
      case 'BABYBOOK': {
        return 'The Remento Baby Book';
      }
      case 'FREE': {
        return storytellerPersonQuery.data?.name
          ? `${genitiveCase(storytellerPersonQuery.data.name.first)} New Project`
          : 'New Project';
      }
    }

    return '';
  }, [projectType, storytellerPersonQuery.data?.name]);

  const includes = useMemo(() => {
    switch (projectType) {
      case 'BIOGRAPHY':
      case 'AUTOBIOGRAPHY': {
        return [
          '1 year of weekly prompts',
          `${products.books} hardcover color-printed ${products.books > 1 ? 'books' : 'book'}`,
          ...(products.ebook ? ['E-book included'] : []),
        ];
      }
      case 'BABYBOOK': {
        return [
          `${products.books} hard-cover color-printed ${products.books > 1 ? 'books' : 'book'} with up to 200 pages`,
          'Weekly reminders to record',
          'Unlimited collaborators',
          ...(products.ebook ? ['E-book included'] : []),
        ];
      }
      case 'FREE': {
        return [
          ...(products.books > 0
            ? [`${products.books} hardcover color-printed ${products.books > 1 ? 'books' : 'book'}`]
            : []),
          ...(products.ebook ? ['1 e-book'] : []),
          'Unlimited recordings',
          'Weekly reminders to record',
        ];
      }
    }

    return [];
  }, [products.books, products.ebook, projectType]);

  if (projectType == null) {
    throw new Error('project-type-cannot-be-null');
  }

  if (isMobile) {
    if (sticky) {
      return (
        <ProjectCheckoutSummaryMobileSticky type={projectType} title={title} products={products} includes={includes} />
      );
    }
    return <ProjectCheckoutSummaryMobile type={projectType} title={title} products={products} includes={includes} />;
  }

  return <ProjectCheckoutSummaryDesktop type={projectType} title={title} products={products} includes={includes} />;
}
