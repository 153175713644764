import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ProjectType } from '@remento/types/project';

import { RMButton } from '@/components/RMButton/RMButton';
import { RMStepper } from '@/components/RMStepper/RMStepper';
import { useIsMobileViewport } from '@/hooks/useIsMobileViewport';
import {
  AudienceTarget,
  ProjectCheckoutAudienceForm,
} from '@/modules/project-checkout/components/ProjectCheckoutAudienceForm/ProjectCheckoutAudienceForm';
import { ProjectCheckoutDesktopLayout } from '@/modules/project-checkout/components/ProjectCheckoutLayout/ProjectCheckoutDesktopLayout';
import { ProjectCheckoutMobileLayout } from '@/modules/project-checkout/components/ProjectCheckoutLayout/ProjectCheckoutMobileLayout';
import { ProjectCheckoutSummaryDesktop } from '@/modules/project-checkout/components/ProjectCheckoutSummary/ProjectCheckoutSummary.desktop';
import { ProjectCheckoutSummaryMobile } from '@/modules/project-checkout/components/ProjectCheckoutSummary/ProjectCheckoutSummary.mobile';
import {
  getCheckoutAudiencePath,
  getCheckoutAutobiographyContactPath,
  getCheckoutBabyHostPath,
  getCheckoutBiographyDeliveryPath,
  getSigninPath,
  RementoPage,
} from '@/modules/routing';
import { useServices } from '@/Services';
import { useSignOut, useUser } from '@/services/api/auth/auth.service.hook';
import { usePersonQuery } from '@/services/api/person';

const BABYBOOK_GIFT_TYPEFORM = 'https://remento.typeform.com/to/fNVz3Nb4';

interface InternalProjectCheckoutAudiencePage {
  projectType: ProjectType;
}

function InternalProjectCheckoutAudiencePage({ projectType }: InternalProjectCheckoutAudiencePage) {
  const isMobile = useIsMobileViewport();

  const { redirectService, checkoutAnalyticsService } = useServices();
  const user = useUser();
  const navigate = useNavigate();
  const signOut = useSignOut();
  const [selectedAudienceTarget, setSelectedAudienceTarget] = useState(
    projectType === ProjectType.BIOGRAPHY ? AudienceTarget.SomeoneElse : AudienceTarget.Myself,
  );

  const personQuery = usePersonQuery(user?.personId);
  const signedInUser = useMemo(() => {
    const name = personQuery?.data?.name ?? null;
    const email = user?.communicationChannels.email ?? '';

    return {
      name,
      email,
    };
  }, [user, personQuery?.data]);

  const handleSignIn = useCallback(async () => {
    await redirectService.registerRedirect('signed-in', getCheckoutAudiencePath(projectType));
    navigate(getSigninPath({ backupLocalData: true }));
  }, [projectType, navigate, redirectService]);

  const title = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return 'I’d like to...';
    }

    return 'Who will be telling stories on Remento?';
  }, [projectType]);

  const audienceOptions = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return [
        { label: 'Create my own baby book', value: AudienceTarget.Myself },
        { label: 'Gift a baby book', value: AudienceTarget.SomeoneElse },
      ];
    }

    return [
      { label: 'Me', value: AudienceTarget.Myself },
      { label: 'My family member', value: AudienceTarget.SomeoneElse },
    ];
  }, [projectType]);

  const bookSummaryDetails = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return {
        type: 'BABYBOOK' as const,
        title: 'The Remento Baby Book',
        products: {
          price: 99,
          officialPrice: 168,
          books: 1,
          ebook: false,
        },

        includes: [
          '1 hard-cover color-printed book with up to 200 pages',
          'Weekly reminders to record',
          'Unlimited collaborators',
        ],
      };
    }

    return {
      type: 'BIOGRAPHY' as const,
      title: 'The Remento Book',
      products: {
        price: 99,
        officialPrice: 168,
        books: 1,
        ebook: false,
      },

      includes: ['1 year of weekly prompts', '1 hardcover color-printed book'],
    };
  }, [projectType]);

  const handleGoToNextStep = useCallback(async () => {
    checkoutAnalyticsService.onCheckoutPersonalized(selectedAudienceTarget);

    let nextStep: string;
    switch (projectType) {
      case ProjectType.BIOGRAPHY:
        nextStep =
          selectedAudienceTarget === AudienceTarget.SomeoneElse
            ? getCheckoutBiographyDeliveryPath()
            : getCheckoutAutobiographyContactPath();
        break;

      case ProjectType.BABY: {
        if (selectedAudienceTarget === AudienceTarget.SomeoneElse) {
          window.open(BABYBOOK_GIFT_TYPEFORM, '_blank');
          return;
        } else {
          nextStep = getCheckoutBabyHostPath();
        }
        break;
      }

      default:
        throw new Error(`The ${projectType} project is not implemented.`);
    }

    navigate(nextStep);
  }, [checkoutAnalyticsService, navigate, projectType, selectedAudienceTarget]);

  const handlePurchaseGiftCard = useCallback(() => {
    switch (projectType) {
      case ProjectType.BIOGRAPHY:
        window.open('https://remento.typeform.com/to/tgkWawHL', '_blank');
        break;

      case ProjectType.BABY:
        window.open(BABYBOOK_GIFT_TYPEFORM, '_blank');
        break;

      default:
        throw new Error(`The ${projectType} project is not implemented.`);
    }
  }, [projectType]);

  const checkoutAudienceForm = (
    <ProjectCheckoutAudienceForm
      selectedValue={selectedAudienceTarget}
      onSelectedChange={(selected: AudienceTarget) => setSelectedAudienceTarget(selected)}
      user={user ? signedInUser : null}
      title={title}
      audienceOptions={audienceOptions}
      onSignIn={handleSignIn}
      onSignOut={signOut}
      onPurchaseGiftCard={projectType !== ProjectType.BABY ? handlePurchaseGiftCard : null}
    />
  );

  const steps = useMemo(() => {
    if (projectType === ProjectType.BABY) {
      return [
        { label: 'Project type' },
        { label: 'Your profile', disabled: true },
        { label: 'Book details', disabled: true },
        { label: 'Review', disabled: true },
      ];
    }

    if (selectedAudienceTarget === AudienceTarget.Myself) {
      return [{ label: 'Storyteller' }, { label: 'Details', disabled: true }, { label: 'Review', disabled: true }];
    }
    return [
      { label: 'Storyteller' },
      { label: 'Delivery', disabled: true },
      { label: 'Details', disabled: true },
      { label: 'Review', disabled: true },
    ];
  }, [projectType, selectedAudienceTarget]);

  const Stepper = useMemo(
    () => <RMStepper layout={isMobile ? 'progress' : 'stepper'} activeStepIndex={0} steps={steps} />,
    [isMobile, steps],
  );

  useEffect(() => {
    checkoutAnalyticsService.onCheckoutArrived('book');
  }, [checkoutAnalyticsService]);

  if (isMobile) {
    return (
      <ProjectCheckoutMobileLayout
        Stepper={Stepper}
        ContinueButton={
          <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
            Continue
          </RMButton>
        }
        Header={<ProjectCheckoutSummaryMobile {...bookSummaryDetails} />}
      >
        {checkoutAudienceForm}
      </ProjectCheckoutMobileLayout>
    );
  }

  return (
    <ProjectCheckoutDesktopLayout
      Stepper={Stepper}
      ContinueButton={
        <RMButton background="primary" onClick={handleGoToNextStep} fullWidth autoLoading>
          Continue
        </RMButton>
      }
      RightPanel={<ProjectCheckoutSummaryDesktop {...bookSummaryDetails} />}
      user={user ? signedInUser : null}
      showPurchaseGiftCard={projectType !== ProjectType.BABY}
      onSignIn={handleSignIn}
      onSignOut={signOut}
      onPurchaseGiftCard={handlePurchaseGiftCard}
    >
      {checkoutAudienceForm}
    </ProjectCheckoutDesktopLayout>
  );
}

export function ProjectCheckoutAudiencePage() {
  const params = useParams();
  const projectType = params.project === 'baby' ? ProjectType.BABY : ProjectType.BIOGRAPHY;

  return (
    <RementoPage type="empty" pageAnalyticsPayload={{ projectType }}>
      <InternalProjectCheckoutAudiencePage projectType={projectType} />
    </RementoPage>
  );
}
